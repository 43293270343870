import {SiteBean, siteSchema} from '@/service/model/SiteBean'
import {MaterialBean, materialSchema} from '@/service/model/MaterialBean'
import {JSONSchemaType} from 'ajv'
import {QuarryBean, quarrySchema} from '@/service/model/QuarryBean'
import {AddressBean, addressSchema} from '@/service/model/AddressBean'

export interface SiteManagerOrderBean {
  id: string
  reference: string
  express_order: boolean
  allowed_week_days?: string | null
  site: SiteBean
  quarry: QuarryBean
  
  material: MaterialBean
  material_quantity: number
  quarry_material_reference?: string | null
  material_delivered_quantity?: number | null
  material_cadence?: number | null
  material_cadence_periodicity?: number | null
  
  backload_material?: MaterialBean | null
  backload_material_delivered_quantity?: number | null
  backload_material_quantity?: number | null
  quarry_backload_material_reference?: string | null
  
  start_day: string
  truck_type?: string
  delivery_type: string
  quotation_request_address: AddressBean
}

export function otherMaterial(order: SiteManagerOrderBean, material: MaterialBean): MaterialBean | undefined {
  if (order.material === material) {
    return order.backload_material || undefined
  }
  if (order.backload_material === material) {
    return order.material
  }
  return undefined
}

export const siteManagerOrderSchema: JSONSchemaType<SiteManagerOrderBean> = {
  type: 'object',
  properties: {
    id: {
      type: ['string']
    },
    reference: {
      type: ['string']
    },
    express_order: {
      type: ['boolean']
    },
    allowed_week_days: {
      type: ['string'],
      nullable: true
    },
    site: {
      ...siteSchema
    },
    quarry: {
      ...quarrySchema
    },
    material: {
      ...materialSchema
    },
    material_quantity: {
      type:['number']
    },
    material_delivered_quantity: {
      type:['number'],
      nullable: true
    },
    material_cadence: {
      type: ['number'],
      nullable: true
    },
    material_cadence_periodicity: {
      type: ['number'],
      nullable: true
    },
    backload_material: {
      ...materialSchema,
      nullable: true
    },
    backload_material_quantity: {
      type: ['number'],
      nullable: true
    },
    backload_material_delivered_quantity: {
      type: ['number'],
      nullable: true
    },
    start_day: {
      type: ['string']
    },
    truck_type: {
      type: ['string'],
      nullable: true
    },
    delivery_type: {
      type: ['string']
    },
    quarry_material_reference: {
      type: ['string'],
      nullable: true
    },
    quarry_backload_material_reference: {
      type: ['string'],
      nullable: true
    },
    quotation_request_address: {
      ...addressSchema
    }
  },
  required: [
    'id', 'reference', 'express_order',
    'site', 'quarry',
    'material', 'material_quantity',
    'start_day', 'delivery_type'
  ],
  additionalProperties: true
}

export const siteManagerOrderArraySchema: JSONSchemaType<Array<SiteManagerOrderBean>> = {
  type: 'array',
  items: siteManagerOrderSchema
}
